<template>
  <div class="row table-responsive fixed-table-body">
    <table class="table">
      <thead>
        <th>Line Item No.</th>
        <th>Contract Item No.</th>
        <th>Spec. No.</th>
        <th>Material Description</th>
        <th>Supplier and Complete Address</th>
        <th>Manufacturer and Complete Address</th>
        <th>VDOT/Locality Use Insp./Test By</th>
        <th></th>
      </thead>
      <tbody>
        <template v-for="(item, index) in items">
          <tr :key="index">
            <td>{{ item.lineitemno }}</td>

            <td>{{ item.contractitemno }}</td>

            <td>{{ item.specno }}</td>

            <td>{{ item.materialdescription}}</td>

            <td><pre>{{ item.supplierinfo }}</pre></td>
            <td><pre>{{ item.manufacturerinfo }}</pre></td>
            <td>{{ item.inspinfo }}</td>

            <td><button class="btn btn-danger" @click="deleteTask(index)">Delete</button></td>
          </tr>
        </template>
        <tr class="w-100">

          <td style="word-break:break-all;">
          <input type="text" name="lineitemno" placeholder="" label="Line Item No."
              v-model="lineitemno" /></td>
          <td style="word-break:break-all;"><input type="text" name="contractitemno" placeholder=""
              label="Contract Item No." v-model="contractitemno" /></td>
          <td style="word-break:break-all;"><input type='text' name="specno" placeholder="" label="Spec. No."
              v-model="specno" /></td>
          <td style="word-break:break-all;"><input type='text' name="materialdescription" placeholder=""
              label="Material Description" v-model="materialdescription" /></td>

          <td style="word-break:break-all;">
          <FormulateInput type="textarea" name="supplierinfo" v-model="supplierinfo"/></td>

          <td style="word-break:break-all;">
          <FormulateInput type="textarea" name="manufacturerinfo" v-model="manufacturerinfo"/></td>

          <td style="word-break:break-all;"><input type='text' name="inspinfo" placeholder="" label="Inspection Info"
              v-model="inspinfo" /></td>
          <td><span class="btn btn-primary" @click="addTask()">Add To List</span></td>
        </tr>
      </tbody>

    </table>

    <FormulateInput type="hidden" :value="items" name="items" />
  </div>
</template>

<script>
  export default {
    name: 'Task',
    props: ['items'],
    data() {
      return {
        lineitemno: '',
        contractitemno: '',
        specno: '',
        materialdescription: '',
        supplierinfo: '',
        manufacturerinfo: '',
        inspinfo: ''
      }
    },
    computed: {
      total() {
        var total = 0;
        this.items.forEach(item => {
          total += parseFloat(this.lineitemtotal(item.quantity, item.unitprice, item.taxrate))
        })

        return total;
      }
    },
    methods: {
      lineitemtotal: function (q, u, t) {
        if (isNaN(t)) t = 4;
        var pretax = q * u;
        var total = pretax * (t / 100) + pretax
        if (t == 0) {
          return pretax.toFixed(2);
        } else {
          return total.toFixed(2);
        }

      },
      addTask: function () {
        this.items.push({
          lineitemno: this.lineitemno,
          contractitemno: this.contractitemno,
          specno: this.specno,
          materialdescription: this.materialdescription,
          supplierinfo: this.supplierinfo,
          manufacturerinfo: this.manufacturerinfo,
          inspinfo: this.inspinfo
        });
        this.lineitemno = '';
        this.contractitemno = '';
        this.specno = '';
        this.materialdescription = '';
        this.supplierinfo = '';
        this.manufacturerinfo = ''
        this.inspinfo = '';
        // alert(JSON.stringify(this.items))
      },
      deleteTask: function (idx) {
        if (confirm(`Are you sure you want to delete ? This cannot be undone.`)) {

          this.items.splice(idx, 1)
        }
      }
    }



  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  h3 {
    margin: 40px 0 0;
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  li {
    display: inline-block;
    margin: 0 10px;
  }

  a {
    color: #42b983;
  }

  .tasks {
    display: flex;
  }

  .tasks .formulate-input {
    margin-right: 1.5em;
  }
</style>