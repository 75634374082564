<template>
    <div>
    <!-- some code condensed for display reasons -->
 
<FormulateInput
    type="group"
    name="project"
  >
    <div class="row d-md-flex justify-content-between p-3">
        <div class="col-3">
        <FormulateInput type="text" name="submittalno" label="Submittal No." validation="required" error-behavior="submit" v-model="project.name"/>
        </div>

        <div class="col-3">
        <FormulateInput type="text" name="projectnumber" label="Project Number" v-model="project.projectnumber"/>
        </div>

        <div class="col-3">
        <FormulateInput type="text" name="projectlocation" label="Project Location" v-model="project.projectlocation"/>
        </div>

        <div class="col-3">
        <FormulateInput type="text" name="contractnumber" label="Contract Number" v-model="project.contractnumber"/>
        </div>
    </div>
    <div class="row d-md-flex p-3">
        <div class="col-3">
            <FormulateInput type="text" name="district" label="District" v-model="project.district"/>
        </div>
        <div class="col-3">
            <FormulateInput type="text" name="county" label="County" v-model="project.county"/>
        </div>
        <div class="col-6"></div>
    </div>

    <div class="row d-md-flex justify-content-between p-3">
        <FormulateInput type="textarea" name="primecontractor" label="Prime Contractor with Address" v-model="project.primecontractor"/>

        <FormulateInput type="textarea" name="subcontractor" label="Sub Contractor with Address" v-model="project.subcontractor"/>

        <FormulateInput type="textarea" name="contactinfo" label="Name and Phone # of Contact Person" v-model="project.contactinfo"/>
    </div>
    <FormulateInput
        type="date"
        name="submitteddate"
        label="Submitted Date"
        placeholder="Sample date placeholder"
        />

    </FormulateInput>
    </div>
</template>

<script>
export default {
  name: 'Project',
  props: ['project'],
  data () {
      return {
      }
  },
  methods: {
  
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>