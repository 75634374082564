<template>
<div id="app">
    <div v-if="!loading">

    <h1>New C25 Entry</h1>
    <FormulateForm
      v-model="values"
    >
    <FormulateInput type="hidden" name="invoiceid" />
    <div class="container">
      <div class="d-xl-flex justify-content-between" >
        
        <div id="projectInfo" class="border p-2">
          <h3>Project Information</h3>
          <Project :project="project"/>
        </div>
      </div>
    </div>
    <div class="container">
      <div id="taskInfo" class="container border mt-2 p-2">
        <h3>Item List</h3>
        <Task :items="items"/>
      </div>
    </div>

    
  </FormulateForm>
</div>
<div v-else>
<div class="spinner-grow text-danger" role="status">
  <span class="sr-only"></span>
</div>
</div>
<div v-if="generatetest === 1">
  <span class="text-muted">Generating the excel... </span>
  <div class="spinner-grow text-dark" role="status" style="height: 1rem;width: 1rem;">
    <span class="sr-only">Loading...</span>
  </div>
</div>
<div v-else-if="generatetest === 2">
  <span class="text-success">File has been generated!</span>
</div>
<div v-if="savetest === 1">
  <span class="text-muted">Saving the file to machine... </span>
  <div class="spinner-grow text-dark" role="status" style="height: 1rem;width: 1rem;">
    <span class="sr-only">Loading...</span>
  </div>
</div>
<div v-else-if="savetest === 2">
  <span class="text-success">File has been saved!</span>
</div>
<div v-if="emailtest === 1">
  <span class="text-muted">Emailing customer...</span>
  <div class="spinner-grow text-dark" role="status" style="height: 1rem;width: 1rem;">
    <span class="sr-only">Loading...</span>
  </div>
</div>
<div v-else-if="emailtest === 2">
  <span class="text-success">Email sent!</span>
</div>
<button type="button" class="btn btn-primary m-3" @click="submitForm" >Submit To Workflow</button>
  </div>

</template>
<script>

import Project from '../components/Project.vue'
import Task from '../components/Task.vue'
export default {
  name: 'Invoice',
  data () {
    return {
      values: {},
      items: [],
      id: this.$route.params.id,
      project: {},
      loading: true,
      generatetest: 0,
      savetest: 0,
      emailtest: 0,

    }
  },
  components: {
    Project,
    Task
  },
  created () {
    
    this.loading=false;
    
    
  },
  methods : {
      submitForm () {
          console.log(JSON.parse(JSON.stringify(this.values)))
          this.generatetest=1;
          this.emailtest=1;
          this.savetest=1;
          setTimeout(() => {
            this.generatetest=2;
          },2000);
          setTimeout(() => {
            this.savetest=2;
          },4000);
          setTimeout(() => {
            this.emailtest=2;
          },6000);

      },
      emailInvoice () {

      },
      generateInvoicePDF () {

      },
      deleteInvoice () {

      }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
